import {deletes, get, post, put} from '~/api/index';
import {alerts} from '~/composables/alerts';
import appConfig from '~/app.config';
import handleException from '~/services/exceptions';
import {ApiReturn, ApiReturnCode} from '~/@types/clouAuth';
import access from '~/composables/access';
import {showError} from '#app';
import {useAuthUserStore} from '~/store/authUser';
import {User} from '~/@types/user';
import {useCompanyStore} from '~/store/comapny';
import {CompanyBasic, CompanyProfileType} from '~/@types/company';

export default class CompanyApi {
  public static resourceName = '/companies';

  /**
   * List companies
   */
  static async list (): Promise<void> {
    try {
      // Check access
      if (!access.verify('COMPANIES-VIEW')) {
        alerts().show(appConfig.accessError('view companies'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
        return;
      }

      const companyStore = useCompanyStore();
      const response = await get(`${this.resourceName}`, companyStore.listParams);

      if (response.data && response.data.data && response.data.meta) {
        companyStore.setListData({
          companies: response.data.data,
          meta: response.data.meta,
        });
        return;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get companies'), 'error');
      return;
    }
  }

  /**
   * Create a company [PUBLIC]
   *
   * @companyData
   */
  static async publicCreate (companyData: {firstName: string; lastName: string, email: string; phoneNumber: string; companyName: string; companyPosition: string, token: string}): Promise<ApiReturnCode> {
    try {
      const response = await post(`/public${this.resourceName}`, companyData);

      if ([200, 406].includes(response.code)) {
        return response.code;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('create a company'), 'error');
      return 500;
    }
  }

  /**
   * Create a company
   *
   * @companyData
   */
  static async create (companyData: {firstName: string | null; lastName: string | null, email: string | null; userDid: string | null; companyName: string; companyPosition: string; profileType: CompanyProfileType}): Promise<string | 406 | 500> {
    try {
      const response = await post(`${this.resourceName}`, companyData);

      if (response.code === 200) {
        return response.data;
      } else if (response.code === 406) {
        return response.code;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('create a company'), 'error');
      return 500;
    }
  }

  /**
   * Show a company
   *
   * @param did
   */
  static async show (did: string): Promise<ApiReturn> {
    try {
      // Check access
      if (!access.verify('COMPANIES-VIEW')) {
        alerts().show(appConfig.accessError('view company'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
        return {data: null, code: 401};
      }

      const response = await get(`${this.resourceName}/${did}`);
      if ([200, 404].includes(response.code)) {
        return response;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get company data'), 'error');
      return {data: null, code: 500};
    }
  }

  /**
   * Update company(s)
   *
   * @param dids
   * @param data
   */
  static async update (dids: string[], data: any): Promise<void> {
    try {
      // Check access
      if (!access.verify('COMPANIES-MODIFY')) {
        alerts().show(appConfig.accessError('modify companies'), 'warning');
        return;
      }

      const response = await put(`${this.resourceName}`, {dids, data});

      if (response.code === 200) {
        return;
      }
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('update the company'), 'error');
      return;
    }
  }

  /**
   * A basic company list
   *
   * @param searchKeyword
   * @param page
   * @param itemsPerPage
   * @param mustIncludeCompanyDids - This will fetch these company regardless of the search keywords
   */
  static async basicList (searchKeyword = '', page = 1, itemsPerPage = 20, mustIncludeCompanyDids: string[] = []): Promise<CompanyBasic[]> {
    try {
      // Check access
      if (!access.verify('COMPANIES-VIEW')) {
        alerts().show(appConfig.accessError('view company'), 'warning');
        showError({ statusCode: 401, statusMessage: 'Permission issue' });
      }

      const companies = await get(`${this.resourceName}`, {
        page,
        sortBy: '[]',
        isBasic: true,
        itemsPerPage,
        filters: JSON.stringify([{operation: 'search', field: 'name', value: searchKeyword}]),
        mustIncludeCompanyDids,
      });

      if (companies.code === 200) {
        return companies.data.data;
      }

      throw new Error();
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('get companies'), 'error');
      return [];
    }
  }

  /**
   * Transfer ownership
   *
   * @param companyDid
   * @param newOwnerDid
   * @param password  - Password for the current user who preforms the action
   */
  static async transferOwnership (companyDid: string, newOwnerDid: string, password: string): Promise<ApiReturnCode> {
    try {
      // Check access
      if (!access.verify('COMPANIES-DELETE')) {
        alerts().show(appConfig.accessError('delete companies'), 'warning');
        return 401;
      }

      const response = await put(`${this.resourceName}-transfer-ownership`, {companyDid, newOwnerDid, password});

      if (response.code === 200) {
        alerts().show(`Ownership transfer successful${access.isCompanyOwner() ? ', You will be logged out soon.' : '.'}`, 'success');
        const thisUserRollId = access.roleId();
        if (!thisUserRollId || thisUserRollId > 3) {
          setTimeout(() => {
            const {$logout} = useNuxtApp();
            $logout();
          }, 5000);
        }
        return 200;
      } else if (response && response.code) {
        return response.code;
      }

      return 500;
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('delete the company'), 'error');
      return 500;
    }
  }

  /**
   * Delete company(s)
   */
  static async delete (dids: string[]): Promise<void> {
    try {
      // Check access
      if (!access.verify('COMPANIES-DELETE')) {
        alerts().show(appConfig.accessError('delete companies'), 'warning');
        return;
      }

      const response = await deletes(`${this.resourceName}`, {dids});

      if (response.code === 200) {
        return;
      }
    } catch (error) {
      handleException(error);
      alerts().show(appConfig.errorMessage('delete the company'), 'error');
      return;
    }
  }
}
